.Evento {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  grid-column-gap: 0px;
  grid-row-gap: 20px;

  width: 100%;
  height: 100vh;
  min-height: 500px;

  padding: 45px 35px;

  // background-image: url("../../images/homeBackground.jpg");
  // background-position: center;
  // background-size: cover;

  .mainSection {
    grid-area: 1 / 1 / 2 / 2;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      padding-bottom: 30px;
    }

    input {
      padding: 20px 25px;
      width: 100%;

      font-size: 16px;
      font-weight: normal;
      color: black;

      outline: 1px;
      border: 2px solid black;
      border-radius: 15px;
      background-color: transparent;
    }
  }

  .actions {
    grid-area: 2 / 1 / 3 / 2;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    column-gap: 10px;
    row-gap: 20px;
    width: 100%;

    padding-bottom: 15px;

    button {
      padding: 20px 25px;
      width: 100%;

      font-size: 16px;
      font-weight: normal;
      color: black;

      outline: 1px;
      border: 2px solid black;
      border-radius: 15px;
      background-color: transparent;

      cursor: pointer;

      &:hover {
        -webkit-box-shadow: 0px 0px 9px -3px rgb(107, 107, 107);
        -moz-box-shadow: 0px 0px 9px -3px rgb(126, 126, 126);
        box-shadow: 0px 0px 9px -3px rgb(121, 121, 121);
      }

      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }

    .cta {
      font-weight: 600;
      color: black;
    }

    .salir {
      padding: 0;
      border: none;
      color: black;

      &:hover {
        box-shadow: none;
        font-weight: bold;
      }
    }
  }
}
