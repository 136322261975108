html, body {
  background-color: white; /* Cambia el color de fondo a blanco */
  overscroll-behavior: contain; /* Controla el comportamiento de overscroll */
}

* {
  margin: 0;padding: 0;line-height: 1;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: black;
}

//Scrollbar
/* Estilos para la barra de desplazamiento en navegadores WebKit (Chrome, Safari) */
* {
    // Estilos de la barra de desplazamiento vertical
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #999;
    }
  
    // Estilos de la barra de desplazamiento horizontal
    &::-webkit-scrollbar-track:horizontal {
      background-color: #f2f2f2;
    }
  
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: #ccc;
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb:horizontal:hover {
      background-color: #999;
    }
  }