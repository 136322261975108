.cardCategoryContainer {
  padding: 10px;

  border-radius: 10px;
  
  .cardCategory {
    display: flex;
    flex-direction: column;
    
    padding: 20px;
    
    gap: 10px;
    border: 1px solid black;
    border-radius: 20px;

    h3 {
      font-size: 20px;
    }
    
    p {
      border-bottom: 1px solid grey;
      padding-bottom: 10px;
      font-size: 16px;
    }

    
    .horizontalContainer {
      width: 100%;
      height: max-content;
      
      display: flex;
      white-space: nowrap; /* Evita que los elementos se envuelvan en la siguiente línea */
      gap: 10px;
      
      overflow-x: auto; /* Activa el scroll horizontal */
    }

  }
}