.cardItemContainer {
  .cardItem {
    display: flex;
    gap: 10px;

    padding: 15px;
    width: -webkit-max-content;
    width: max-content;
    height: clamp(140px, 30vw, 200px);;

    justify-content: center;
    align-content: flex-start;

    border-radius: 10px;
    // background-color: rgb(245, 250, 255);
    border: 1px solid black;

    .imageContainer {
      // izquierda
      height: 100%;
      width: 25%;

      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      height: 100%;
      width: 100%;

      h3 {
        font-size: 20px;
        font-weight: 600;
      }

      .sizesContainer {
        display: flex;
        white-space: nowrap;

        span {
          padding: 10px;
          margin-right: 10px;

          border: 1px solid grey;
          border-radius: 10px;

          font-size: 14px;
          cursor: pointer;

          &.selected {
            background-color: black;
            color: white;
          }
        }
      }

      .colorsContainer {
        display: flex;
        white-space: nowrap;

        span {
          padding: 10px;
          margin-right: 10px;

          border: 1px solid grey;
          border-radius: 10px;

          font-size: 14px;
          cursor: pointer;

          &.selected {
            background-color: black;
            color: white;
          }
        }
      }
    }
  }
}